import React, { Component } from 'react';

import './../../assets/css/main.css';
import './../../assets/css/noscript.css';

export default class Home extends Component {

  render() {
    return (
      <React.Fragment>
        <section id="main">
          <h1>Hey there!</h1>
          <hr />
          <p>Welcome to my link shortener, currently only I can create links, sorry.</p>
			    <hr />
          <a href="https://jtang.dev/link-shortener" class="button">Find out more</a>
        </section>

        <footer id="footer">
          <ul class="copyright">
            <li>Created by <a href="https://jtang.dev">Jonathan Tang</a></li>
          </ul>
        </footer>
      </React.Fragment>
    );
  }

}
