import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import Routes from "./routes";
import * as serviceWorker from './serviceWorker';

import Amplify from "aws-amplify";
import config from "./config";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "links",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  }
});

ReactDOM.render(
  <Router>
    <Routes />
  </Router>,
  document.getElementById('wrapper')
);

serviceWorker.unregister();
