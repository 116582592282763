import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home/Home";
import Link from "./containers/Link/Link";
import NotFound from "./containers/NotFound/NotFound";
import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/:id" exact component={Link} props={childProps} />
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <Route component={NotFound} />
  </Switch>;