import React, { Component } from 'react';

import './../../assets/css/main.css';
import './../../assets/css/noscript.css';

export default class NotFound extends Component {

  render() {
    return (
      <React.Fragment>
        <section id="main">
          <h1>404 Not Found</h1>
          <hr />
          <p>The page your was looking for probably doesn't exist...</p>
			    <hr />
          <a href="https://jtang.dev" class="button">Check out my work</a>
        </section>

        <footer id="footer">
          <ul class="copyright">
            <li>Created by <a href="https://jtang.dev">Jonathan Tang</a></li>
          </ul>
        </footer>
      </React.Fragment>
    );
  }

}
