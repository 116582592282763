import React, { Component } from 'react';
import { API } from "aws-amplify";

import './../../assets/css/main.css';
import './../../assets/css/noscript.css';

export default class Link extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isCountdownFinished: false,
      secondsRemaining: 5,

      title: "",
      url: "",
      error: null
    }
  }

  async componentDidMount() {
    const link = await this.getLink();

    if (link.notFound) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false, url: link.Link, title: link.Title });
      this.interval = setInterval(() => this.countdownTick(), 1000);
    }

    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  countdownTick() {
    this.setState(previousState => ({
      secondsRemaining: previousState.secondsRemaining - 1
    }));

    if (this.state.secondsRemaining === 0) {
      clearInterval(this.interval);
      this.setState({ isCountdownFinished: true });
    }
  }

  getLink() {
    return API.get("links", `/${this.props.match.params.id}`)
  }

  renderTitle() {
    if (this.state.isLoading) {
      return <h2>Please wait...</h2>
    } else if (this.state.error) {
      return <h2>Invalid link</h2>
    } else {
      return <h2>{this.state.title}</h2>
    }
  }

  renderSponsoredContent() {
    return <p>This space will probably be replaced with sponsored content in the future.</p>
  }

  renderButton() {
    if (this.state.isLoading) {
      return <a class="button disabled">Please wait...</a>
    } else if (this.state.error) {
      return <a class="button disabled">Invalid link</a>
    } else {
      return (
        <a href={this.state.url} class={this.state.isCountdownFinished ? "button" : "button disabled"}>
          {this.state.isCountdownFinished ? "Continue to link" : `Please wait ${this.state.secondsRemaining} seconds...`}
        </a>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <section id="main">
          {this.renderTitle()}
          <hr />
          {this.renderSponsoredContent()}
			    <hr />
          {this.renderButton()}
        </section>

        <footer id="footer">
          <ul class="copyright">
            <li>Created by <a href="https://jtang.dev">Jonathan Tang</a></li>
          </ul>
        </footer>
      </React.Fragment>
    );
  }

}
